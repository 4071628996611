import { render, staticRenderFns } from "./ReportCardPieChart.vue?vue&type=template&id=8d9c6836&scoped=true"
import script from "./ReportCardPieChart.vue?vue&type=script&lang=js"
export * from "./ReportCardPieChart.vue?vue&type=script&lang=js"
import style0 from "./ReportCardPieChart.vue?vue&type=style&index=0&id=8d9c6836&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d9c6836",
  null
  
)

export default component.exports